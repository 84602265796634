import React from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import { color } from "../components/defaults/styles/theme"
import GeneralFooter from '../components/general_footer';
import EventPageComponent from '../components/event';

const EventPage = ({pageContext, data}) => {
  const {recordId} = pageContext;

  return (
    <Layout>
      <EventPageComponent
        data={data.airtable.data}
        recordId={recordId}
      />
      <GeneralFooter theme={color.main_light} />
    </Layout>
  )
};

export default EventPage;


export const query = graphql`query EventE($id: String!) {
  airtable(id: {eq: $id}, table: {eq: "agenda"}) {
    id
    recordId
    data {
      password_protected
      agenda_description {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      agenda_agenda {
        childMarkdownRemark {
          html
        }
      }
      agenda_start_time
      agenda_tags
      agenda_attendee_limit
      breakout_session
      stream_session
      agenda_registration
      agenda_title
      agenda_duration
      agenda_teaser
      agenda_startups {
        recordId
        data {
          startup
        }
      }
      agenda_partners {
        recordId
        data {
          name
        }
      }
      agenda_dial_in
      agenda_host {
        id
        recordId
        data {
          include
          name
          firstname
          surename
          profession
          partner_company {
            data {
              name
            }
          }
          startup_company {
            data {
              startup
            }
          }
          custom_company
          image {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED, width: 350)
              }
            }
          }
        }
      }
      agenda_contact {
        recordId
        data {
          include
          name
          firstname
          surename
          profession
          email
          mail_public
          phone_number
          partner_company {
            data {
              name
            }
          }
          startup_company {
            data {
              startup
            }
          }
          custom_company
          image {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED, width: 350)
              }
            }
          }
        }
      }
      agenda_speaker {
        id
        recordId
        data {
          include
          name
          profession
          partner_company {
            data {
              name
            }
          }
          startup_company {
            data {
              startup
            }
          }
          custom_company
          image {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED, width: 350)
              }
            }
          }
        }
      }
      agenda_casestudies {
        data {
          cs_title
          cs_publish
          cs_teaser {
            childMarkdownRemark {
              html
            }
          }
          cs_startup {
            recordId
            data {
              startup
              vertical
              country
              city
            }
          }
        }
      }
      agenda_rearview {
        recordId
        data {
          sort
          publish
          password_protected
          rearview_tags
          rearview_thumbnail {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED, width: 450)
              }
            }
          }
          rearview_description {
            childMarkdownRemark {
              excerpt(format: PLAIN, pruneLength: 240)
            }
          }
          rearview_release
          rearview_title
          rearview_partners {
            data {
              name
            }
          }
          rearview_startups {
            data {
              startup
            }
          }
          rearview_agenda {
            data {
              agenda_start_time
            }
          }
        }
      }
    }
  }
}`