import publishFilter from './publish';
import companyLink from './companyLink';
import Flags from '../../../data/raw/flags'


const getCompany = ( el, {
  outputAll = true,
  sort = false,
  filterIncludes = true
} = {}) => {

  el = el.data !== undefined ? el.data : el;

  // 2021-08-31T12:49+02
  // el is input variable and should be a user (which includes on ore multiple companies)
  // ---
  // outputAll is an argument that decides whether it should output all companies (true – is default)
  // or rather only display the first one (false)
  // ---
  // sort decides whether it will be grouped by company type (partner, startups, custom)
  // and solely sorted within those (they are appended afterwards) (false – is default) 
  // or if there should be another sorting after the appending process
  // (company types will be mixed) (true)
  // ---
  // filterIncludes allows to toggle between having all companies listed in output (false)
  // or only those which (for partners/startups) are set to active/include in the CMS (true - default)

  let result = [];

  const addToResult = ( j ) => {
    j.sort( (a, b) => (b.name > a.name ? -1 : 0))
    j.forEach(k => result.push(k))
  }

  if ( el.partner_company && el.partner_company.length > 0 ) {
    let partners = []
    el.partner_company.forEach(e => (
      partners.push({
        name: e.data.name,
        url: companyLink(e.data.name, "partner"),
        active: e.data.active ? true : false,
        data: e.data
      })
    ))
    
    if (filterIncludes) {
      partners = publishFilter(partners, "active")
    }
    addToResult(partners)
  } 
  
  if ( el.startup_company && el.startup_company.length > 0 ) {
    let startups = []
    el.startup_company.forEach(e => (
      startups.push({
        name: e.data.startup,
        url: companyLink(e, "startup"),
        active: e.data.include ? true : false,
        data: e.data
      })
    ))
    
    if (filterIncludes) {
      startups = publishFilter(startups, "include")
    }
    addToResult(startups)
  }

  if ( el.custom_company ) {
    let custom = [];

    // for explanation please see https://stackoverflow.com/a/57121244
    // multiple entries in custom company can be entered like that
    // first company, second company, "third company", "fourth, company", 'fifth company'
    let temp = el.custom_company.match(/(?<=("|'))[^("|')]+?(?=("|')(?:\s*?,|\s*?$))|(?<=(?:^|,)\s*?)(?:[^,("|')\s][^,("|')]*[^,("|')\s])|(?:[^,("|')\s])(?![^("|')]*?("|')(?:\s*?,|\s*?$))(?=\s*?(?:,|$))/g);
    temp.forEach(e => (
      custom.push({
        name: e,
        url: null,
        active: null,
        data: null
      })
    ))

    addToResult(custom)
  }

  // edgecase – if no company name is specified
  if (!el.partner_company && !el.startup_company && !el.custom_company) {
    addToResult([{
      name: null,
      url: null,
      active: null,
      data: null
    }])
  }

  // test for dummySettings in Flags
  if ( typeof Flags.settings.dummySettings.company === "object" ) {
    Flags.settings.dummySettings.company.forEach( el => (
      result = result.filter( e => e.name !== null ? !e.name.includes( el ) : null)
    ))
  } else if ( typeof Flags.settings.dummySettings.company === "string" ) {
    result = result.filter( e => e.name !== null ? !e.name.includes( Flags.settings.dummySettings.company ) : null )
  }

  if (sort) {
    result.sort( (a, b) => (b.name > a.name ? -1 : 1) )
  }

  if (!outputAll) {
    result = result[0]
  }

  return result;
}

export default getCompany;