import Flags from '../../../data/raw/flags';

const passLockFilter = (url, boolean) => {
  if (boolean) {
    return `/${Flags.settings.protectedArea.url}${url}`
  } else {
    return `${url}`
  }
};

export default passLockFilter;