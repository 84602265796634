import Flags from '../../../data/raw/flags';

const companyLink = (el, type) => {
  let link;
  
  if (type === "partner") {
    link =
      "/partners/" +
      decodeURIComponent(
        el
          .replace(/\./g, "")
          .replace(/&/g, "")
          .replace(/\s/g, "")
          .replace(/-/g, "")
          .replace(/é/g, "e")
      )
  } else if (type === "startup") {
    link =
      `/${Flags.settings.protectedArea.url}/startups/` + el.recordId
  }

  return link;
}

export default companyLink;